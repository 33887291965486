import '../src/routes/Components/css/Banner.css';
import '../src/routes/Components/css/Dashboard.css';
// import '../src/routes/Components/css/IWantToSection.css';
import '../src/routes/Components/css/Navbar.css';
import '../src/routes/Components/css/ReleaseCalendar.css';
import '../src/components/css/Dropdown.css';
import '../src/routes/Components/css/Homepage.css';
import '../src/components/ReportBody/ReportBody.css';
import '../src/components/SDReportBody/SDReport.css';
import '../src/components/SubscriptionModal/SubscriptionModal.css';

export { default } from '@abyss/core/next-app';
